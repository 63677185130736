import styled from "styled-components";

export const Wrapper = styled.div`
	margin-bottom: 2rem;
	background: ${p => p.theme.elevation2};
	padding: 0 1rem;
	/* box-shadow: 0px 6px 10px #dfe4eb; */
	border-radius: 4px;
	overflow: hidden;

	&:last-child {
		margin-bottom: unset;
	}
`;

export const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2rem 1.5rem;
	cursor: pointer;

	h4 {
		margin: 0;
        font-family: var(--body-text-regular), sans-serif;
        max-width: 35ch;
	}
`;

export const Arrow = styled.svg`

	transition: 0.3s;

    fill: ${p => p.theme.bodyFontColor};

	${({ active }) =>
		active &&
		`
    transform: rotate(180deg);
  `}
`;

export const Details = styled.div`
	max-height: 0;
	transition-duration: 0.5s;
	overflow: hidden;
	text-align: left;

	p {
		padding: 1.5rem 1.5rem;
		color: ${p => p.theme.bodyParagraphColor};
		line-height: 1.8;
	}

	${({ active }) =>
		active &&
		`
    max-height: 450px;
  `}
`;
