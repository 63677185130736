import React, { useState } from "react"
// import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { Wrapper, Title, Arrow, Details } from "./styles"

const Accordion = ({ title, description }) => {
  const [isVisible, toggle] = useState(false)
  return (
    <Wrapper>
      <Title onClick={() => toggle(!isVisible)}>
        <h4>{title}</h4>
        <Arrow
          active={isVisible}
          alt="Arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="11"
          fill="none"
        >
          <path
            d="M9 2l.7-.7L9 .6l-.7.7.7.7zm-7.3 8.8l8-8.1-1.4-1.4-8 8.1 1.4 1.4zm6.6-8.1l8 8.1 1.4-1.4-8-8.1-1.4 1.4z"
          />
        </Arrow>
      </Title>
      <Details active={isVisible}>
        <p>{description}</p>
      </Details>
    </Wrapper>
  )
}

export default Accordion
