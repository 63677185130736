import React, { useState, useContext } from "react"
import styled from "styled-components"
import Accordion from "../components/Accordion"
import Seo from "@components/seo"
// import { Breadcrumbs } from "@components"
import {
  ErrorMessage,
  SuccessMessage,
  Input,
  Label,
  OutputLabel,
  TextArea,
  MultiSelect,
  Button,
} from "@components/common/form"
import { MediumDisplay } from "../styles/typography"
import { researchData } from "../data/researchData"
import { FirebaseContext } from "../components/Firebase"

const ResearchPage = () => {
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    contribute: [],
    message: "",
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (data.email !== data.confirmEmail) {
      setErrorMessage("Emails do not match")
    } else {
      firebase.db
        .collection("research")
        .add({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          contribute: data.contribute,
          message: data.message,
        })
        .then(() => {
          setSuccessMessage(
            `Hello, ${data.firstName}. Thank you for your interest in integrating with us. We will be in touch with you shortly.`
          )
        })
        .catch(error => {
          setErrorMessage(error.message)
        })

      setTimeout(() => {
        setData({
          firstName: "",
          lastName: "",
          email: "",
          confirmEmail: "",
          contribute: [],
          message: "",
        })
      }, 3000)
    }
  }

  return (
    <>
      <Seo title="Research" />
      <Container>
        <Banner>
          {/* <Breadcrumbs to="/" page="Home" current="Research" /> */}
          <MediumDisplay>Research</MediumDisplay>
        </Banner>
        <AccordionContainer>
          {researchData.map(data => {
            return (
              <Accordion
                key={data.index}
                title={data.title}
                description={data.description}
              />
            )
          })}
        </AccordionContainer>
        <FormContainer>
          <OutputLabel label="request access to research" />
          <FormWrap onSubmit={handleSubmit}>
            <FormTopRow>
              <FlexItem>
                <Label htmlFor="first name">first name *</Label>
                <Input
                  name="first name"
                  placeholder="first name"
                  onChange={e => {
                    setData({
                      ...data,
                      firstName: e.target.value,
                    })
                  }}
                  value={data.firstName}
                  required
                />
              </FlexItem>
              <FlexItem>
                <Label htmlFor="last name">last name *</Label>
                <Input
                  name="last name"
                  placeholder="last name"
                  onChange={e => {
                    setData({
                      ...data,
                      lastName: e.target.value,
                    })
                  }}
                  value={data.lastName}
                  required
                />
              </FlexItem>
            </FormTopRow>
            <FormBottomRow>
              <FlexItem>
                <Label htmlFor="email">email *</Label>
                <Input
                  placeholder="email"
                  type="email"
                  onChange={e => {
                    setData({
                      ...data,
                      email: e.target.value,
                    })
                  }}
                  value={data.email}
                  required
                />
              </FlexItem>
              <FlexItem>
                <Label htmlFor="confirm email">confirm email *</Label>
                <Input
                  name="confirm email"
                  placeholder="confirm email"
                  value={data.confirmEmail}
                  onChange={e => {
                    setData({
                      ...data,
                      confirmEmail: e.target.value,
                    })
                  }}
                  required
                />
              </FlexItem>
            </FormBottomRow>
            {/* {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}

            <Label htmlFor="contribute interest">
              area(s) of interest *
              <MultiSelect
                options={[
                  {
                    value: "collectData",
                    label: "Collect Data",
                  },
                  {
                    value: "publishResearch",
                    label: "Publish",
                  },
                  {
                    value: "Partner on proprietary research",
                    label: "Partner on proprietary research",
                  },
                  {
                    value: "Supervise a Research Project",
                    label: "Supervise a Research Project",
                  },
                  {
                    value: "Propose a Research Project",
                    label: "Propose a Research Project",
                  },
                  {
                    value: "Contribute to a closed-source research projects",
                    label: "Contribute to a closed-source research projects",
                  },
                  {
                    value: "Contribute to an open-source research project",
                    label: "Contribute to an open-source research project",
                  },
                  {
                    value: "Peer Review",
                    label: "Peer Review",
                  },
                  {
                    value: "Event Prediction",
                    label: "Event Prediction",
                  },
                ]}
                name="contribute interest"
                placeholder="How would you like to contribute?"
                isClearable={true}
                isSearchable={false}
                closeOnSelect={true}
                isMulti={true}
                onChange={e => {
                  setData({
                    ...data,
                    contribute: e,
                  })
                }}
                value={data.contribute}
              />
            </Label>
            <Label htmlFor="tell us about your needs">
              tell us about your needs
              <TextArea
                name="tell us about your needs"
                placeholder="hello, "
                onChange={e => {
                  setData({
                    ...data,
                    message: e.target.value,
                  })
                }}
                value={data.message}
              />
              {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {!!successMessage && (
                <SuccessMessage>{successMessage}</SuccessMessage>
              )}
            </Label>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <NextButton type="submit">
                submit
                <ArrowRight
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <Path
                    d="M3.33334 7.99967H12.6667M12.6667 7.99967L8.00001 3.33301M12.6667 7.99967L8.00001 12.6663"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </ArrowRight>
              </NextButton>
            </div>
          </FormWrap>
        </FormContainer>
      </Container>
    </>
  )
}

export default ResearchPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  margin-inline: auto;
`

const Banner = styled.section`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 4px;
  min-height: 25vh;

  > p {
    color: ${p => p.theme.bodyParagraphColor};
  }
`

const AccordionContainer = styled.div`
  height: auto;
`

const FormContainer = styled.div`
  min-height: 100vh;
  padding: 5rem 0;
`

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  padding: 0 8px;
`

const FormTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const FormBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const NextButton = styled(Button)`
  margin-top: 1rem;
  justify-content: flex-end;
`

const Path = styled.path``

const ArrowRight = styled.svg`
  height: 16px;
  width: 16px;
  margin: 0.5rem;
  vertical-align: middle;

  ${Path} {
    stroke: ${p => p.theme.fillColor};
  }
`
