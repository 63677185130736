export const researchData = [
    {
      title: "Collect Data",
      description:
        "earn by helping us collect real data in your daily life for any specific project or our knowledge graph (in development). Standard contracts are available for closed-sourced projects while data collection for our open-knowledge graph allows you to earn based on the reduction in entropy you provide. The larger the reduction the more you earn!... Coming Soon.",
      index: 0,
    },
    {
      title: "Publish and Link Your Own Research and Academic Projects",
      description:
        "Use the Zenith text editor for your research and assignments. With the world's most fine-grained citation encoding, your work can be natively linked to any information and source – allowing for automated source discovery and reference list / bibliography generation. Publish your work and connect your research to our knowledge graph to be discovered and referenced.",
      index: 1,
    },
    {
      title: "Partner on proprietary research",
      description:
        "partner with zenith to implement bespoke process automation, and data-collection pipelines that improve operating margins and business scalability.",
      index: 2,
    },
    {
      title: "Supervise a Research Project",
      description:
        "if you are an expert you can apply to supervise a research project in your area of expertise. all project supervisors will earn remuneration as negotiated on a per project basis and are entitled to become a partner for the project.",
      index: 3,
    },
    {
      title: "Propose a Research Project",
      description:
        "Looking to initiate your own research project? Research with Zenith in order to leverage our pipelines and community for the best results. For both closed-sourced projects and open-sourced prompts. For open projects any contributions that extend the graph from a prompt trigger an event that ensures the prompter is delivered every updates.",
      index: 4,
    },
    {
      title: "Contribute to a closed-source research projects",
      description:
        "Scale your research career by joining teams for our closed sourced research projects as an individual contractor. Contributions range from data-collection, to peer-review, and as far as novel algorithm design – this initiative is open to anyone at any point in their research career",
      index: 5,
    },
    {
      title: "Contribute to an open-source research project",
      description:
        "Publish your own research and analyses linked to any prompt or previously published work.",
      index: 6,
    },
    {
      title: "Peer Review",
      description:
        "Post fine grained comments and analyses for previously published research to improve confidence scores for the research outcomes and to build your track-record and expertise scores.",
      index: 7,
    },
    {
      title: "Event Prediction",
      description:
        "Build your analytical track record over certain industries by making predictions about future events.",
      index: 8,
    },
  ]
